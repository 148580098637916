<template>
  <div class="mainContain">
    <div class="box" >
      <div class="ViewTitle">{{ title }}</div>
      <div class="contain gery">
        <el-form
          :label-position="labelPosition"
          label-width="150px"
          :model="form"
        >
          <el-form-item label="Email">
            <el-input v-model="form.email" placeholder="Please enter email"></el-input>
          </el-form-item>
          <el-form-item label="Verification code" class="codeBox">
            <el-input v-model="form.code" placeholder="Please enter the verification code"></el-input>
            <el-button
              type="text"
              :disabled="disabled"
              class="setCode"
              @click="getCode()"
              >{{ setCode }}
            </el-button>
          </el-form-item>
          <div class="submit" style="display: flex; justify-content: flex-end;margin-right: 5vh">
            <el-button class="cancleStyle" @click="cancleSubmit()"
            >Cancel</el-button
            >
            <el-button  class="confirmStyle" @click="submit()">Confirm</el-button>
          </div>
        </el-form>
      </div>
    </div>

    <div class="islider" v-if="show">
      <bc-slider
        @validImg="validImg"
        @close="onClose"
        :backgroupImg="backgroupImg"
        :moveImg="moveImg"
        :log="true"
      ></bc-slider>

      <!-- @getImg="getImg" -->
    </div>
  </div>
</template>
<script>
import bcSlider from "@/components/slider";
import { emailSend, captcha, captchaVerify, upEmail } from "@/api/user";
import { shareUrl } from "@/utils/vxshare.js";

export default {
  name: "ChangeEmail",
  components: { bcSlider },
  data() {
    return {
      title: "Modify email",
      titleEn: "Modify email",
      labelPosition: "right",
      form: {
        areaCode: "",
        email: "",
        code: "",
      },
      disabled: false,
      setCode: "Verification code",
      setCodeEn: "Verification code",
      languageSelect: "",
      backToken: "",
      secretKey: "",
      backgroupImg: "",
      moveImg: "",
      show: false,
    };
  },
  computed: {},
  created() {
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }
    let type = this.$route.query.type;
    this.title = type == 1 ? "Modify email" : "Bind email";
    this.getImg();
    let url = window.location.host;
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        title: "Oil & Gas GPT",
      };
      shareUrl(shareData);
    }, 1000);
  },
  methods: {
    onClose() {
      this.show = false;
      this.getImg();
    },
    // 获取滑动验证码（下方有格式截图）
    getImg() {
      captcha().then((res) => {
        this.backToken = res.data.token;
        this.secretKey = res.data.secretKey;
        this.backgroupImg = res.data.originalImageBase64;
        this.moveImg = res.data.jigsawImageBase64;
      });
    },
    // 操作滑动后返回值，并传去后端验证
    validImg(moveX, fn) {
      // moveX为小滑块滑动的距离
      // 330为背景图的宽度，可设置为动态变量，310是偏移量
      const moveLeftDistance = (moveX * 310) / parseInt(330);
      const data = {
        pointJson: JSON.stringify({ x: moveLeftDistance, y: 5.0 }),
        token: this.backToken,
        type: 2,
        email: this.form.email,
        // sender: this.form.phone,
      };
      captchaVerify(data)
        .then((res) => {
          const result = res;
          if (result.data) {
            this.onClose();
            // 调用登录的请求方法
            let data = {
              email: this.form.email,
              captchaToken: res.data,
              event:
                this.title == "Modify email"
                  ? "UPDATE_EMAIL_EMS"
                  : "UPDATE_EMAIL_EMS",
            };
            emailSend(data).then((respon) => {
              // this.$message({
              //   message: respon.msg,
              //   type: "success",
              // });
              // this.tackBtn(); //验证码倒数60秒
              if (!respon.data.data) {
                this.$message({
                  message: respon.msg,
                  type: "success",
                });
                this.tackBtn(); //验证码倒数60秒
              } else {
                console.log(respon.data);
                let data1 = JSON.parse(respon.data.data);
                this.$message({
                  message: data1.error_msg,
                  type: "error",
                });
              }
            });
          } else {
            fn(false);
          }
        })
        .catch(() => {
          fn(false);
        });
      this.show = true;
    },
    //获取验证码 并只验证手机号 是否正确
    getCode() {
      const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      if (!this.form.email) {
        this.$message.error("Please enter email");
        return false;
      }
      if (!reg.test(this.form.email)) {
        this.$message.error("Please enter the correct email number");
        return false;
      }

      this.show = true;
    },
    tackBtn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.setCode = "verification code";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.setCode = "Try again in "+time+" s";
          time--;
        }
      }, 1000);
    },
    cancleSubmit(){
      this.$router.push("/secure")
    },
    submit() {
      const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      if (!this.form.email) {
        this.$message.error("Please enter email");
        return false;
      }
      if (!reg.test(this.form.email)) {
        this.$message.error("Please enter the correct email number");
        return false;
      }
      if (!this.form.code) {
        this.$message.error("Please enter the verification code");
        return false;
      }
      this.form.token = localStorage.getItem("Zyaccess_token"); // 竹云token
      this.form.language = this.languageSelect;
      upEmail(this.form).then(() => {
        this.$message({
          message: "Modified successfully！", //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            location.reload();
            //跳转页面或执行方法
            this.getUserCom();
            //跳转页面或执行方法
            this.$router.go(-1);
          },
        });
      });
    },
    getCodeEn() {
      const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      if (!this.form.email) {
        this.$message.error("Please enter email");
        return false;
      }
      if (!reg.test(this.form.email)) {
        this.$message.error("Please enter the correct email number");
        return false;
      }
      this.show = true;
    },
    tackBtnEn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.setCodeEn = "Verification code";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.setCodeEn = "Try again in " + time + " s";
          time--;
        }
      }, 1000);
    },
    submitEn() {
      const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      if (!this.form.email) {
        this.$message.error("Please enter email");
        return false;
      }
      if (!reg.test(this.form.email)) {
        this.$message.error("Please enter the correct email address");
        return false;
      }
      if (!this.form.code) {
        this.$message.error("Please enter the verification code");
        return false;
      }
      this.form.token = localStorage.getItem("Zyaccess_token"); // 竹云token
      this.form.language = this.languageSelect;
      upEmail(this.form).then(() => {
        this.$message({
          message: "Modified successfully！", //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            this.getUserCom();
            //跳转页面或执行方法
            this.$router.go(-1);
          },
        });
      });
    },
  },
};
</script>
<style scoped lang="scss">
.mainContain {
  width: 65vw;
  border-radius: 9px;
  margin: 0 1vw;
  height: 75vh;
  padding: 0 2vw 3vh 0;
  position: relative;
  background-image: url("../../assets/oilba.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;

}
.box {
  padding: 2vh 0;
  border-radius: 9px;
  background: #fff;

  .ViewTitle {
    height: 8vh;
    line-height: 8vh;
    border-bottom: 1px solid #ebebeb;
    font-size: 1vw;
    color: #333333;
    font-weight: 400;
    padding: 0 3vw;
  }

  .contain {
    padding: 8vh 3vw;
    width: fit-content;

    .el-form-item {
      font-size: 0.9vw;
      margin-bottom: 3vh;
    }

    .submit {
      width: fit-content;
      height: 6vh;
      line-height: 6vh;
      text-align: center;
      border-radius: 9px;
      position: absolute;
      bottom:5vh;
      right:5vw;
      color: #ffffff;
      cursor: pointer;

    }
    .cancleStyle {
      margin-top: 1vh;
      width: fit-content;
      padding: 0 3vw;
      height: 6vh;
      border-radius: 9px;
      opacity: 1;
      border: 1px solid #bf0022;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #bf0022;
      line-height: 20px;
      -webkit-background-clip: text;
    }
    .confirmStyle {
      margin-top: 1vh;
      width: fit-content;
      padding: 0 3vw;
      height: 6vh;
      border-radius: 9px;
      margin-left: 2vw;
      cursor: pointer;
      border: none;
      background-color: #bf0022;
      font-size: 16px;
      font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

::v-deep .el-input__prefix {
  left: 90%;
  transition: all 0.3s;
}
::v-deep .el-input.is-disabled .el-input__inner {
  background-color: white !important;
  border: #575757 1px solid !important;
  //color: #c0c4cc;
  cursor: not-allowed;
}

::v-deep .el-input .el-input__inner {
  background-color: white !important;
  border: #575757 1px solid !important;
  //color: #c0c4cc;
  height: 6vh;
}

::v-deep {
  .el-input__inner {
    height: 48px;
  }

  .el-form-item__label {
    color: #666;
    font-size: 0.9vw;
    padding-right: 32px;
    line-height: 48px;
  }

  .codeBox {
    .el-form-item__content {
      display: flex;

      .el-input {
        flex: 1;
      }

      .setCode {
        width: 120px;
        height: 48px;
        // line-height: 50px;
        text-align: center;
        border-radius: 4px;
        background: var(--main-color);
        color: #fff;
        font-size: 14px;
        margin-left: 15px;
      }
    }
  }
}
</style>
